






















































































































































































































































































































































































































































































import PageHeader from '~/components/app/page-header.vue'
import {Component, Ref, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import QueryParserMixin from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import {createRequest} from '~/utils/network-request'
import {Course, EnrollmentStatus} from '~/components/data-class/data-class'
import PhoneWts from '~/components/phone-wts.vue'
import {randomColor} from '~/utils/random-color'
import moment from 'moment'
import {Dict, isEmpty} from '~/utils/misc'
import {OfflineStudent} from '~/components/course/offline-student-model'
import EnrollmentMessageDialog from '~/components/course/enrollment-message-dialog.vue'
import copy from 'copy-to-clipboard'
import {OfflineClass, OfflineClassType} from '~/components/course/offline-course-model'
import OfflineClassStructuredDisplay from '~/components/course/offline-class-structured-display.vue'
import CourseData from '~/components/course/course-data'
import {VSelect} from 'vuetify/lib'
import SeriesSelect from '~/components/autocomplete/series-select.vue'
import SeriesData from '~/components/series/series-data'
import {schoolLookup} from "@afterschool.dev/as-data-admin"

enum OfflineStudentListFilterType {
    COURSE,
    COURSE_TYPE,
    COURSE_SERIES,
    STATUS,
    SUBJECT,
    DSE_YEAR,
    RENEWAL_STATUS
}

export abstract class OfflineStudentListFilterData {
}

export class OfflineStudentListCourseFilterData extends OfflineStudentListFilterData {
    courseId: string = ''
    classes: number[] | undefined = undefined
}

export class OfflineStudentListCourseTypeFilterData extends OfflineStudentListFilterData {
    type: 'trial' | 'live' | 'online' | '' = ''
}

export class OfflineStudentListSeriesFilterData extends OfflineStudentListFilterData {
    seriesId: number | undefined = undefined
}

export class OfflineStudentListStatusFilterData extends OfflineStudentListFilterData {
    paid: boolean = true
}

export class OfflineStudentListSubjectFilterData extends OfflineStudentListFilterData {
    subject: string = ''
}

export class OfflineStudentListYearFilterData extends OfflineStudentListFilterData {
    years: number[] = []
}

export class OfflineStudentListRenewalStatusFilterData extends OfflineStudentListFilterData {
    renewed: boolean | undefined = undefined
}

const filterTypeData = {
    [OfflineStudentListFilterType.COURSE]: OfflineStudentListCourseFilterData,
    [OfflineStudentListFilterType.COURSE_TYPE]: OfflineStudentListCourseTypeFilterData,
    [OfflineStudentListFilterType.COURSE_SERIES]: OfflineStudentListSeriesFilterData,
    [OfflineStudentListFilterType.STATUS]: OfflineStudentListStatusFilterData,
    [OfflineStudentListFilterType.SUBJECT]: OfflineStudentListSubjectFilterData,
    [OfflineStudentListFilterType.DSE_YEAR]: OfflineStudentListYearFilterData,
    [OfflineStudentListFilterType.RENEWAL_STATUS]: OfflineStudentListRenewalStatusFilterData,
}

const isOrNot = (b: boolean) => b ? 'is' : 'is not'

export class OfflineStudentListFilter {
    private type: OfflineStudentListFilterType = OfflineStudentListFilterType.COURSE

    set filterType(type: OfflineStudentListFilterType) {
        this.data = new filterTypeData[type]()
        this.type = type
    }

    get filterType(): OfflineStudentListFilterType {
        return this.type
    }

    equals: boolean = true
    data: OfflineStudentListFilterData = new OfflineStudentListCourseFilterData()

    toString() {
        const data = this.data as any
        switch (this.type) {
            case OfflineStudentListFilterType.COURSE:
                let str = 'Course is ' + data.courseId
                if (data.classes && data.classes.length)
                    str += ', Classes is ' + data.classes.join(', ')
                return str
            case OfflineStudentListFilterType.COURSE_TYPE:
                return 'Course type is ' + data.type
            case OfflineStudentListFilterType.COURSE_SERIES:
                return 'Series is ' + SeriesData.getSeries(data.seriesId)!.title
            case OfflineStudentListFilterType.STATUS:
                return 'Order status is ' + (data.paid ? 'paid' : 'unpaid')
            case OfflineStudentListFilterType.SUBJECT:
                return `Subject ${isOrNot(this.equals)} ${data.subject}`
            case OfflineStudentListFilterType.DSE_YEAR:
                return 'DSE year is ' + data.years.join(', ')
            case OfflineStudentListFilterType.RENEWAL_STATUS:
                let s = isEmpty(this.data) ? 'N/A' : this.data ? 'renewed' : 'not renewed'
                return 'Renewal status is ' + s
        }
    }
}

@Component({
    components: {
        SeriesSelect,
        PageHeader,
        PhoneWts,
        EnrollmentMessageDialog,
        OfflineClassStructuredDisplay,
        VSelect
    },
    metaInfo() {
        return {
            title: 'Enrollment List'
        }
    }
})

export default class OfflineStudentList extends mixins(QueryParserMixin, PaginationMixin) {

    msgDialogVisible = false
    studentSearchKeyword = ''

    // Table data
    studentsData: OfflineStudent[] = []
    allPagesClassesDict: Dict<Dict<OfflineClass>> = {} // key is course id, value is all classes of all versions of the course
    step = 100
    currentPage = 1
    totalCount = 0
    showChangeClass = false

    // Edit time slot dialog.
    editingStudentTimeSlot: number = -1
    editingStudentCourseId: string = ''
    editingStudentMemberId: string = ''

    allClassesDict: Dict<Dict<OfflineClass>> = {} // key is course id, value is all classes of all versions of the course

    CourseData = CourseData

    OfflineStudentListCourseFilterData = OfflineStudentListCourseFilterData
    OfflineStudentListCourseTypeFilterData = OfflineStudentListCourseTypeFilterData
    OfflineStudentListSeriesFilterData = OfflineStudentListSeriesFilterData
    OfflineStudentListStatusFilterData = OfflineStudentListStatusFilterData
    OfflineStudentListSubjectFilterData = OfflineStudentListSubjectFilterData
    OfflineStudentListYearFilterData = OfflineStudentListYearFilterData
    OfflineStudentListRenewalStatusFilterData = OfflineStudentListRenewalStatusFilterData

    get currentPageStudentsData() {
        if (this.step === -1) {
            return this.studentsData
        }
        return this.studentsData.slice((this.currentPage - 1) * this.step, (this.currentPage - 1) * this.step + this.step)
    }

    get selectedAllEnrollments(): boolean {
        return this.currentPageStudentsData.reduce((cumulative, data) => cumulative && this.selectedEnrollments.includes(data.course + ',' + data.member_id), true)
    }

    set selectedAllEnrollments(selected: boolean) {
        const currentPageStudentsData = this.currentPageStudentsData
        this.selectedEnrollments = this.selectedEnrollments.filter((enrollment) => !currentPageStudentsData.some((data) => data.course + ',' + data.member_id === enrollment))
        if (selected) {
            this.selectedEnrollments.push(...this.currentPageStudentsData.map((data) => data.course + ',' + data.member_id))
        }
    }

    selectedEnrollments: string[] = []

    // Filter.
    filters: OfflineStudentListFilter[] = []
    appliedFilters: OfflineStudentListFilter[] = []

    // Filter offline class dialog.
    filterClassDialogVisible = false
    modifyingCourseFilterData: OfflineStudentListCourseFilterData
    filteringCourse: Course = new Course()
    classDialogFilteringVersion: number = 1
    selectedFilterClassIds: number[] = []

    drawerVisible = false

    schoolsData = {}

    EnrollmentStatus = EnrollmentStatus
    OfflineStudentListFilterType = OfflineStudentListFilterType
    OfflineClassType = OfflineClassType
    @Ref() tableWrapperShadowElement: HTMLElement
    @Ref() tableWrapperElement: HTMLElement
    @Ref() messageDialog: EnrollmentMessageDialog

    get selectedStudents() {
        return this.studentsData.filter((sd) => this.selectedEnrollments.includes(sd.course + ',' + sd.member_id))
    }

    async created() {
        this.parseQuery()

        // await this.getStudents()
    }

    async keywordChanged() {
        this.currentPage = 1
        await this.getStudents()
    }

    async getStudents() {
        this.selectedEnrollments = []

        const bodyFilters = this.appliedFilters.filter((filter) => filter.filterType !== OfflineStudentListFilterType.COURSE_SERIES).map((filter) => {
            let filterString = ''
            let data: { course: string, classes: number[] } | EnrollmentStatus | string | number[] | boolean | undefined = undefined
            if (filter.filterType === OfflineStudentListFilterType.COURSE) {
                filterString = 'course'
                data = {
                    course: (filter.data as OfflineStudentListCourseFilterData).courseId,
                    classes: (filter.data as OfflineStudentListCourseFilterData).classes || []
                }
            } else if (filter.filterType === OfflineStudentListFilterType.COURSE_TYPE) {
                filterString = 'course-type'
                data = (filter.data as OfflineStudentListCourseTypeFilterData).type
            } else if (filter.filterType === OfflineStudentListFilterType.SUBJECT) {
                filterString = 'subject'
                data = (filter.data as OfflineStudentListSubjectFilterData).subject
            } else if (filter.filterType === OfflineStudentListFilterType.STATUS) {
                filterString = 'status'
                data = EnrollmentStatus.UNPAID
                if ((filter.data as OfflineStudentListStatusFilterData).paid) {
                    data = EnrollmentStatus.PAID
                }
            } else if (filter.filterType === OfflineStudentListFilterType.DSE_YEAR) {
                filterString = 'dse'
                data = (filter.data as OfflineStudentListYearFilterData).years
            } else if (filter.filterType === OfflineStudentListFilterType.RENEWAL_STATUS) {
                filterString = 'renewal'
                data = (filter.data as OfflineStudentListRenewalStatusFilterData).renewed
            }

            const json = {
                type: filterString,
                positive: filter.equals
            }
            if (data !== undefined) {
                json['data'] = data
            }
            return json
        })

        const seriesBodyFilters: { type: string, positive: boolean, data: {} }[] = this.appliedFilters.filter((filter) => filter.filterType === OfflineStudentListFilterType.COURSE_SERIES).map((filter) => {
            const seriesId = (filter.data as OfflineStudentListSeriesFilterData).seriesId || ''
            return SeriesData.seriesDict[seriesId].courses.map((cid) => {
                return {
                    type: 'course',
                    positive: filter.equals,
                    data: {
                        course: cid,
                        classes: []
                    }
                }
            })
        }).flat()
        bodyFilters.push(...seriesBodyFilters)

        const studentsRes = await createRequest('/offline-courses/students/filter', 'post', {
            skip: 0 + '',
            limit: 0 + ''
        }, {
            filter: bodyFilters,
            keyword: this.studentSearchKeyword
        }).send()

        const sd: OfflineStudent[] = studentsRes.data.students
        this.studentsData = sd.map(s => {
            s.phone = s.offline_data ? s.offline_data.phone : s.member.order_phone
            s.email = s.offline_data ? s.offline_data.email : s.member.order_email
            return s
        })
        this.totalCount = this.studentsData.length

        this.schoolsData = studentsRes.data.schools

        const bodyClasses = [...new Set(this.studentsData.map((studentData) => studentData.course))]
            .map((cid) => {
                return {
                    course_id: cid,
                    class_ids: [...new Set(this.studentsData.filter((d) => d.course === cid && !!d.offline_data).map((d) => d.offline_data.time_slot))]
                }
            }).filter((data) => data.class_ids.length !== 0)

        if (bodyClasses.length) {
            const classesRes = await createRequest(`courses/classes`, 'post', {}, {
                classes: bodyClasses
            }).send(true)

            for (const courseId of Object.keys(classesRes.data.classes)) {
                this.$set(this.allPagesClassesDict, courseId, classesRes.data.classes[courseId].classes)
            }
        }
    }

    enrollmentDate(timeStamp: number) {
        return moment(timeStamp).format('DD/MM/YYYY HH:mm')
    }

    randomColor(str: string) {
        return randomColor(str)
    }

    offlineClass(courseId: string, timeSlotId: number) {
        let offlineClass
        if (this.allPagesClassesDict[courseId]) {
            offlineClass = this.allPagesClassesDict[courseId][timeSlotId]
        }
        return offlineClass ? offlineClass : new OfflineClass()
    }

    school(schoolNo: number): string {
        const obj: object = schoolLookup[schoolNo + '']
        if (obj != undefined) {
            return schoolLookup[schoolNo + ''].zh
        } else {
            return ''
        }
    }

    selectedTable() {
        const header = ['Date & time', 'Username', 'Name', 'Phone', 'Email', 'Course', 'Class', 'Class Schedule', 'DSE year', 'Status', 'Renewal', 'School', 'Banding']
        return [header, ...this.selectedStudents.map(sd => {
            const time = moment(sd.created)
            const oData = sd.offline_data

            let name = ''
            let offlineClassTitleString = '----'
            let offlineClassScheduleString = '----'

            if (oData) {
                name = oData.name

                const offlineClass = this.allPagesClassesDict[sd.course][oData.time_slot]
                offlineClassTitleString = offlineClass ? offlineClass.title : '----'
                offlineClassScheduleString = offlineClass ? OfflineClass.offlineClassScheduleToMessageString(offlineClass) : '----'
            }

            const statusString = sd.status === EnrollmentStatus.PAID ? 'Paid' : 'Unpaid'
            let renewalString = 'N/A'
            if (sd.renewed !== undefined) {
                renewalString = sd.renewed ? 'Renewed' : 'Not renewed'
            }

            const banding = this.schoolsData[sd.member.school] ? this.schoolsData[sd.member.school].banding : '----'

            return [time.format('DD/MM/YYYY HH:mm:ss'), sd.member.display_name, name, sd.phone, sd.email,
                sd.course, offlineClassTitleString, offlineClassScheduleString, sd.member.dse, statusString, renewalString, this.school(sd.member.school), banding]
        })]
    }

    async copyData() {
        const str = this.selectedTable().map(row => row.join('\t')).join('\n')

        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })

        this.$message.success('Copied')
    }

    async copyExtraData() {
        const header = ['Tutor', 'Course Title', 'Series', 'Version', 'Class Type', 'Attendance', 'Video Progress', 'Rating']
        const res = await createRequest('/courses/enrollments/extra-data', 'post', {}, {
            enrollment_ids: this.selectedStudents.map(sd => sd._id)
        }).send()
        const data = res.data.extra_data
        const table = this.selectedTable()
        for (const [i, row] of table.entries()) {
            if (i === 0) {
                row.push(...header)
            } else {
                const obj = data[i - 1]
                row.push(...[obj.display_name, obj.course_title, obj.series_title, obj.version + '',
                    OfflineClassType[obj.class_type] || '',
                    obj.attendance > -1 ? obj.attendance + '' : '',
                    obj.progress + '',
                    obj.rating || ''])
            }
        }

        const str = table.map(row => row.join('\t')).join('\n')

        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })

        this.$message.success('Copied')
    }

    stepChanged() {
        this.currentPage = 1
        this.getStudents()
    }

    async changeClass(enrollment: OfflineStudent) {

        if (!this.allClassesDict[enrollment.course]) {
            const res = await createRequest(`course/${enrollment.course}/class/classes`, 'get').send(true)
            this.allClassesDict[enrollment.course] = res.data.classes.reduce((cumulate, offlineClass: OfflineClass) => {
                cumulate[offlineClass.id] = offlineClass
                return cumulate
            }, {})
        }

        this.showChangeClass = true
        this.editingStudentTimeSlot = enrollment.offline_data.time_slot
        this.editingStudentCourseId = enrollment.course
        this.editingStudentMemberId = enrollment.member_id
    }

    cancelEditStudent() {
        this.closeEditStudentDialog()
    }

    async saveStudent() {
        const editingStudentData = this.currentPageStudentsData.find((studentData) => studentData.member_id === this.editingStudentMemberId && studentData.course === this.editingStudentCourseId)

        const cls = Object.values(this.availableClasses(this.editingStudentCourseId)!)
        let oldCls, newCls

        if (editingStudentData) {
            oldCls = cls.find(cl => cl.id === editingStudentData.offline_data.time_slot)
            newCls = cls.find(cl => cl.id === this.editingStudentTimeSlot)

            editingStudentData.offline_data.time_slot = this.editingStudentTimeSlot
            this.allPagesClassesDict[this.editingStudentCourseId][this.editingStudentTimeSlot] = this.allClassesDict[this.editingStudentCourseId][this.editingStudentTimeSlot]
        }

        await createRequest(`/offline-course/${this.editingStudentCourseId}/student/${this.editingStudentMemberId}/class`, 'patch', {},
            {class: this.editingStudentTimeSlot}).send()
        this.$message.success('Changed')
        this.closeEditStudentDialog()

        if (oldCls && newCls && oldCls.type === OfflineClassType.IN_PERSON && newCls.type !== OfflineClassType.IN_PERSON)
            this.$alert('已由真人班轉至非真人班。請留意是否需要向學生詢問資料(地址/語言)以寄送筆記。')
    }

    closeEditStudentDialog() {
        this.showChangeClass = false
        this.editingStudentTimeSlot = -1
        this.editingStudentCourseId = ''
        this.editingStudentMemberId = ''
    }

    clearKeywordAndFilters() {
        this.appliedFilters = []
        this.studentSearchKeyword = ''

        this.selectedEnrollments = []
        this.studentsData = []
        this.totalCount = 0
    }

    openFilterDrawer() {
        this.drawerVisible = true
        this.filters = this.appliedFilters.map((f) => Object.assign(new OfflineStudentListFilter(), f))
    }

    addFilter() {
        const filter = new OfflineStudentListFilter()
        this.filters.push(filter)
    }

    async openFilterClassesDialog(filterData: OfflineStudentListCourseFilterData, course: Course) {

        this.filterClassDialogVisible = true
        this.classDialogFilteringVersion = course.current_version
        this.filteringCourse = course


        if (!this.allClassesDict[course._id]) {
            const res = await createRequest(`course/${course._id}/class/classes`, 'get').send(true)
            this.allClassesDict[course._id] = res.data.classes.reduce((cumulate, offlineClass: OfflineClass) => {
                cumulate[offlineClass.id] = offlineClass
                return cumulate
            }, {})
        }
        if (!filterData.classes) {
            filterData.classes = []
        }
        this.selectedFilterClassIds = filterData.classes.map((classId) => classId)
        this.modifyingCourseFilterData = filterData
    }

    saveFilterClass() {
        this.filterClassDialogVisible = false
        this.modifyingCourseFilterData.classes = this.selectedFilterClassIds
    }

    applySelectedFilters() {
        let containsError = this.filters.reduce((cumulative, current) => {
            if (current.filterType === OfflineStudentListFilterType.COURSE) {
                return cumulative || (current.data as OfflineStudentListCourseFilterData).courseId === ''
            } else if (current.filterType === OfflineStudentListFilterType.COURSE_SERIES) {
                return cumulative || (current.data as OfflineStudentListSeriesFilterData).seriesId === undefined
            } else if (current.filterType === OfflineStudentListFilterType.SUBJECT) {
                return cumulative || (current.data as OfflineStudentListSubjectFilterData).subject === ''
            }
            return cumulative || false
        }, false)

        if (containsError) {
            this.$message({
                message: 'Please fill in the filter fields!',
                type: 'error'
            })
            return
        }

        this.appliedFilters = this.filters
        this.drawerVisible = false

        this.currentPage = 1
        this.getStudents()
    }

    tableScrolled() {
        if (this.tableWrapperElement.scrollLeft === 0) {
            // Left most
            this.tableWrapperShadowElement.classList.remove('table-wrapper-inner-shadow-left')
            this.tableWrapperShadowElement.classList.add('table-wrapper-inner-shadow-right')

        }
        if (this.tableWrapperElement.offsetWidth + this.tableWrapperElement.scrollLeft === this.tableWrapperElement.scrollWidth) {
            // Right most
            this.tableWrapperShadowElement.classList.remove('table-wrapper-inner-shadow-right')
            this.tableWrapperShadowElement.classList.add('table-wrapper-inner-shadow-left')

        }
    }

    renewalDisplayData(renewed: boolean | undefined) {
        if (renewed === true) {
            return {
                title: 'Renewed',
                cssClass: 'text-primary border-primary'
            }
        }
        if (renewed === false) {
            return {
                title: 'Not renewed',
                cssClass: 'text-danger border-danger'
            }
        }
        return {
            title: 'N/A',
            cssClass: 'text-grey-700 border-grey-600'
        }
    }

    closeFilterDialog() {
        this.filterClassDialogVisible = false
        this.modifyingCourseFilterData.classes = undefined
    }

    availableClasses(courseId: string) {
        const editingStudentData = this.currentPageStudentsData.find((studentData) => studentData.member_id === this.editingStudentMemberId && studentData.course === courseId)
        if (editingStudentData) {
            return Object.values(this.allClassesDict[courseId]).filter((c) => {
                return c.type === OfflineClassType.VIDEO ||
                    c.lessons.map((l) => l.end).some((end) => end >= new Date().getTime()) ||
                    c.id === editingStudentData.offline_data.time_slot
            })
        }
    }

    @Watch('msgDialogVisible')
    dialogOpened(val) {
        if (!val)
            return

        this.messageDialog.setRemark(this.filters.join('\n'))
    }
}

