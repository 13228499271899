







import {Component, Prop, Vue} from 'vue-property-decorator'
import WtsBtn from './wts-btn.vue'

@Component({
    components: {WtsBtn}
})
export default class PhoneWts extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value: string
}
