















import {CourseSeries} from '~/components/data-class/data-class'
import SeriesData from '~/components/series/series-data'
import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VAutocomplete} from 'vuetify/lib'

@Component({
    components: {
        VAutocomplete
    }
})
export default class SeriesSelect extends Vue {
    @Prop({default: ''}) value!: number

    //  DATA
    series: CourseSeries[] = []

    get localValue() {
        return this.series.find(item => item._id === this.value)
    }

    set localValue(series: any) {
        this.$emit('input', _.isNil(series) ? '' : series._id)
    }

    get options() {
        return this.series
    }

    async created() {
        // GET series
        await SeriesData.update()
        this.series = SeriesData.series
    }

    formatOption(series) {
        return `[${series._id}] ${series.title}`
    }
}
