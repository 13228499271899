

























































































































































import axios from 'axios'
import {DatePickerOptions} from 'element-ui/types/date-picker'
import {mixins} from 'vue-class-component'
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import CourseData from '~/components/course/course-data'
import {OfflineClass, OfflineClassType} from '~/components/course/offline-course-model'
import {OfflineStudent} from '~/components/course/offline-student-model'
import {MessageRecord} from '~/components/message/message-models'
import CourseSelect from '~/components/select/course-select.vue'
import InstructorSelect from '~/components/select/instructor-select.vue'
import DialogMixin from '~/mixins/dialog-mixin'
import {createRequest, getServerBaseURL} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        CourseSelect,
        InstructorSelect,
    }
})
export default class EnrollmentMessageDialog extends mixins(DialogMixin) {
    static permission = AdminPermission.SendWhatsapp

    @Prop({default: () => []})
    targets: OfflineStudent[]

    pickerOptions: DatePickerOptions = {
        shortcuts: [{
            text: 'Today',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setDate(now.getDate())
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }, {
            text: 'Tomorrow',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                date.setDate(now.getDate() + 1)
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }]
    }

    sender: string = ''
    fileUrl: string = ''
    message: string = ''
    remarks: string = ''
    scheduled: Date | number | null = null
    interval = 0

    placeholders = [
        'username',
        'real-name',
        'phone',
        'tutor',
        'course-title',
        'class-title',
        'class-venue',
        'class-schedule',
        'class-whatsapp',
        'class-zoom'
    ]
    @Ref() textArea: Vue
    testingPhone: string = ''
    instructors: {
        member_id: string,
        phone_number: string,
        display_name: string
    }[] = []
    sending = false
    sendConfirmation = false
    inputTestPhone = false
    sendToDuplicated = false

    get enabledPlaceholders() {
        if (this.duplicateExists && !this.sendToDuplicated)
            return [
                'username',
                'real-name',
                'phone'
            ]

        if (this.onlineExists)
            return [
                'username',
                'real-name',
                'phone',
                'course-title',
                'tutor'
            ]

        return this.placeholders
    }

    get duplicateExists(): boolean {
        return this.uniqueTargets.length < this.targets.length
    }

    get onlineExists(): boolean {
        return this.targets.reduce((e, t) => e || !t.offline_data, false)
    }

    get uniqueTargets(): OfflineStudent[] {
        return this.targets.filter((target, index) => this.targets.findIndex((t) => t.phone === target.phone) === index)
    }

    get estTime() {
        const tgs = this.sendToDuplicated ? this.targets.length : this.uniqueTargets.length
        const t = this.interval * tgs
        const MIN = 60
        const HR = 60 * MIN
        const hrs = Math.floor(t / HR)
        const mins = Math.floor((t % HR) / MIN)
        const s = (num) => num > 1 ? 's' : ''
        if (hrs)
            return `${hrs}hr${s(hrs)} ${mins}min${s(mins)}`
        else
            return `${mins}min${s(mins)}`
    }

    @Watch('value', {deep: true})
    valueUpdated(val: MessageRecord) {
        this.fileUrl = val.options.file_url
        this.message = val.options.message
        this.scheduled = val.options.scheduled
    }

    async created() {
        for (let i = 8; i <= 11; i++) {
            this.pickerOptions.shortcuts!!.push({
                text: `${i}pm`,
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    date.setHours(i + 12)
                    date.setMinutes(0)
                    date.setSeconds(0)
                    date.setMilliseconds(0)
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            })
        }
        const res = await createRequest('instructors/senders').send()
        this.instructors = res.data.instructors

        const iRes = await createRequest('message-interval').send()
        this.interval = iRes.data.interval
    }

    setRemark(txt) {
        this.remarks = txt
    }

    fileUrlChanged() {
        if (this.fileUrl && !this.fileUrl.startsWith('http'))
            this.fileUrl = 'https://' + this.fileUrl
    }

    async studentMsgData(student: OfflineStudent) {
        const res: any = {
            'username': student.member.display_name,
            'real-name': student.member.name,
            'phone': student.phone
        }

        if (this.duplicateExists && !this.sendToDuplicated)
            return res

        const course = await CourseData.shouldGetCourse(student.course)
        res['tutor'] = course.display_name
        res['course-title'] = course.title

        if (this.onlineExists)
            return res

        const offlineClass = course.offline_data.classes.find((offlineClass) => offlineClass.id === student.offline_data.time_slot)!
        const schedule = OfflineClass.offlineClassScheduleToMessageString(offlineClass)
        res['class-title'] = offlineClass.title
        res['class-venue'] = offlineClass.type === OfflineClassType.IN_PERSON ? offlineClass.location : '直播課堂'
        res['class-schedule'] = schedule
        res['class-whatsapp'] = offlineClass.whatsapp_link
        res['class-zoom'] = offlineClass.zoom_link

        return res
    }

    async send(test = false) {
        if (!this.message && !this.fileUrl)
            return

        if (test) {
            const data = await this.studentMsgData(this.targets[0])
            const body = {
                message: this.message,
                file_url: this.fileUrl,
                targets: [{
                    member_id: this.targets[0].member_id,
                    data: data
                }],
                test: true,
                test_phone: this.testingPhone,
                sender: this.sender
            }
            await createRequest('/bulk-messages/with-data', 'post', {}, body).send()
            this.$message.info('Test Message Sent')
            this.inputTestPhone = false
            return
        }

        this.sending = true

        const body = {
            message: this.message,
            file_url: this.fileUrl,
            sender: this.sender,
            remark: this.remarks
        }
        if (typeof this.scheduled !== 'number')
            body['scheduled'] = this.scheduled ? this.scheduled.getTime() : 0

        const targets: {}[] = []
        for (const t of (this.sendToDuplicated ? this.targets : this.uniqueTargets)) {
            const data = await this.studentMsgData(t)
            targets.push({
                member_id: t.member_id,
                data: data,
                override_id: t._id
            })
        }

        body['targets'] = targets

        await createRequest('/bulk-messages/with-data', 'post', {}, body).send()

        this.dialogVisible = false
        this.sending = false
    }

    async uploadFile() {
        const e: HTMLInputElement = this.$refs.fileinput as HTMLInputElement
        e.click()
    }

    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        let uploadUrl = ''
        const baseUrl = getServerBaseURL()
        if (baseUrl.startsWith('http://') || baseUrl.startsWith('https://')) {
            uploadUrl = `${baseUrl}file`
        } else {
            uploadUrl = `https://${baseUrl}file`
        }

        let form = new FormData()
        form.append('file', e.target['files'][0])

        const loading = this.$loading({
            target: '.el-dialog',
            text: 'Uploading'
        })
        const token = await this.$auth.getAccessToken()

        const res = await axios({
            method: 'post',
            url: uploadUrl,
            data: form,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        this.fileUrl = res.data['file_url']
        loading.close()
    }

    placeholderClicked(placeholder: string) {
        const textArea: HTMLTextAreaElement = this.textArea.$el.children[0] as HTMLTextAreaElement
        const bracketedPlaceholder = '{' + placeholder + '}'

        //IE support
        if ((document as any).selection) {
            const ieRange = (document as any).selection.createRange()
            ieRange.text = bracketedPlaceholder

            // Move cursor after the inserted text
            ieRange.collapse(false /* to the end */)
            ieRange.select()

            return
        }
        //MOZILLA and others
        else if (textArea.selectionStart || textArea.selectionStart === 0) {
            const startPos = textArea.selectionStart
            const endPos = textArea.selectionEnd
            textArea.value = textArea.value.substring(0, startPos)
                + bracketedPlaceholder
                + textArea.value.substring(endPos, textArea.value.length)
            textArea.selectionStart = startPos + bracketedPlaceholder.length
            textArea.selectionEnd = startPos + bracketedPlaceholder.length
            textArea.focus()
        } else {
            textArea.value += bracketedPlaceholder
        }
        this.message = textArea.value
    }

    @Watch('dialogVisible')
    dialogVisibleChanged(newVal, oldVal) {
        if (newVal) {
            this.inputTestPhone = false
            this.sendConfirmation = false
        }
    }
}
